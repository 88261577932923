/* GENERAL
----------------------------------------------------*/

@import 'bootstrap';

html {
	font-size: 100%;
	font-size: 17px;
	height:100% }

body {
	font-family: "Roboto", sans-serif;
	font-size: 17px;
	line-height: 24px;
	color: #222222;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
	height:100% }
		
a {
	color: #5a7496;
	text-decoration: none;
	 -webkit-transition: all 0.15s;
	 -moz-transition: all 0.15s;
	 -ms-transition: all 0.15s;
	 -o-transition: all 0.15s;
	 transition: all 0.15s }

a:hover,
a:focus,
a:active {
	color: #444445;
	text-decoration: none }
	
a.highlight {
	color: #5a7496 }
	
a.highlight:hover,
a.highlight:focus,
a.highlight:active {
	color: #444445;
	text-decoration: underline }
	
small {
	font-size: 80%;
	font-family: "Roboto", sans-serif }
  
strong {
	font-weight: 700 }

em {
	font-style: italic }
	
abbr {
   border-bottom: none!important;
   cursor: help }
   
.fade.in {
	opacity: .95;
	background-color: #444 }

.noborder {
	border: none!important;
	padding-bottom: 0!important }	





/* COLORBLOCKS
----------------------------------------------------*/

.colorblock {
	padding-top: 50px;
	padding-bottom: 50px }
	
.colorblock.samecolorontop {
	padding-top: 0 }

.colorblock.white {
	background-color: #ffffff;
	color: #444 }

.colorblock.grey {
	background-color: #ddd;
	color: #444 }
	 
.colorblock.grey-dark {
	background-color: #383838;
	color: #fff }
	
.colorblock.black {
	background-color: #222;
	color: #fff }

.colorblock.blue {
	background-color: #5a7496;
	color: #fff }

.colorblock.blue-light {	
	background-color: #6b9ad6;
	color: #fff } 


/* MARGINWRAPPER
----------------------------------------------------*/

.xowrapper {
	position: relative;
	
	margin: 0 2% }
	
	@media (min-width: 600px) {
	.xowrapper {
		margin: 0 3.1% } }
		
	@media (min-width: 768px) {
	.xowrapper {
		margin: 0 4.2% } }
		
	@media (min-width: 992px) {
	.xowrapper {
		margin: 0 5.5% } }
		
	@media (min-width: 1200px) {
	.xowrapper {
		margin: 0 6.5% } }
	
.xowrapper:after {
	clear: both }

.topspace-nointro {
	padding-top: 125px }
	
	@media (min-width: 992px) {
		.topspace-nointro {
			padding-top: 135px } }
			
	@media (min-width: 1200px) {
		.topspace-nointro {
			padding-top: 160px } }

.topspace-xs-0 {
	padding-top: 0 }	
.topspace-xs-1 {
	padding-top: 1em }		
.topspace-xs-2 {
	padding-top: 2em }		
.topspace-xs-3 {
	padding-top: 3em }		
.topspace-xs-4 {
	padding-top: 4em }
		
	@media (max-width: 991px) {
		.topspace-sm-0 {
			padding-top: 0 }	
		.topspace-sm-1 {
			padding-top: 1em }		
		.topspace-sm-2 {
			padding-top: 2em }		
		.topspace-sm-3 {
			padding-top: 3em }		
		.topspace-sm-4 {
			padding-top: 4em } }
			
	@media (min-width: 992px) {	
		.topspace-md-0 {
			padding-top: 0 }		
		.topspace-md-1 {
			padding-top: 1em }		
		.topspace-md-2 {
			padding-top: 2em }		
		.topspace-md-3 {
			padding-top: 3em }		
		.topspace-md-4 {
			padding-top: 4em }
		.topspace-md-5 {
			padding-top: 5em } }

.topspace-intro {
	padding-top: 0 }
	
.bottomspace-xs-0 {
	padding-bottom: 0 }	
.bottomspace-xs-0-5 {
	padding-bottom: 0.5em }		
.bottomspace-xs-1 {
	padding-bottom: 1em }		
.bottomspace-xs-2 {
	padding-bottom: 2em }		
.bottomspace-xs-3 {
	padding-bottom: 3em }		
.bottomspace-xs-4 {
	padding-bottom: 4em }
		
	@media (max-width: 991px) {
		.bottomspace-sm-0 {
			padding-bottom: 0 }	
		.bottomspace-sm-0-5 {
			padding-bottom: 0.5em }		
		.bottomspace-sm-1 {
			padding-bottom: 1em }		
		.bottomspace-sm-2 {
			padding-bottom: 2em }		
		.bottomspace-sm-3 {
			padding-bottom: 3em }		
		.bottomspace-sm-4 {
			padding-bottom: 4em } }
			
	@media (min-width: 992px) {
		.bottomspace-md-0 {
			padding-bottom: 0 }	
		.bottomspace-md-0-5 {
			padding-bottom: 0.5em }		
		.bottomspace-md-1 {
			padding-bottom: 1em }		
		.bottomspace-md-2 {
			padding-bottom: 2em }		
		.bottomspace-md-3 {
			padding-bottom: 3em }		
		.bottomspace-md-4 {
			padding-bottom: 4em }
		.bottomspace-md-5 {
			padding-bottom: 5em } }

.bottomspace-intro {
	padding-bottom: 0 }


/* NAVIGATION
----------------------------------------------------*/

.navbar {
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 18px; } 

@media (min-width: 600px) {
	.navbar {
    	padding-left: 3.1%;
		padding-right: 3.1% } }

@media (min-width: 768px) {
	.navbar {    	
    	padding-left: 4.2%;
		padding-right: 4.2% } }
		
@media (min-width: 992px) {
	.navbar {
    	padding-left: 5.5%;
		padding-right: 5.5% } }
		
@media (min-width: 1200px) {
	.navbar {
    	padding-left: 6.5%;
		padding-right: 6.5%;
		padding-bottom: 14px; } }

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0 }
	
.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto }
	
.collapse {
	display: none }
	
.collapse.in {
  display: block }
  
.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition-timing-function: ease;
    	-o-transition-timing-function: ease;
        transition-timing-function: ease;
	-webkit-transition-duration: .35s;
    	-o-transition-duration: .35s;
        transition-duration: .35s;
	-webkit-transition-property: height, visibility;
    	-o-transition-property: height, visibility;
        transition-property: height, visibility }
        
.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid \9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent }
	
.dropup,
.dropdown {
	position: relative }
	
.dropdown-toggle:focus {
	outline: 0 }
	
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0 9px;
	margin: 2px 0 0;
	font-size: 98%;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
    background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, .15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175) }
    
.dropdown-menu.pull-right {
	right: 0;
	left: auto }
	
.dropdown-menu .divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5 }
	
.dropdown-menu > li > a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: 300;
	line-height: 1.42857143;
	color: #444;
	white-space: nowrap }
	
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	color: #262626;
	text-decoration: none;
	background-color: #f5f5f5 }
	
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
	color: #fff;
	text-decoration: none;
	background-color: #5a7496;
	outline: 0 }
	
.open > .dropdown-menu {
	display: block }
	
.open > a {
	outline: 0 }
	
.dropdown-menu-right {
	right: 0;
	left: auto }
	
.dropdown-menu-left {
	right: auto;
	left: 0 }
	
.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.42857143;
	color: #666;
	white-space: nowrap }
	
.dropdown-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 990 }
	
.pull-right > .dropdown-menu {
	right: 0;
	left: auto }
	
@media (min-width: 1200px) {
	.navbar-right .dropdown-menu {
		right: 0;
		left: auto }
  	.navbar-right .dropdown-menu-left {
    	right: auto;
		left: 0 } }

.nav {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	padding-bottom: 0 }
	
.nav > li {
	position: relative;
	display: block;
	margin-left: 0 }
	
@media (min-width: 1200px) {
	.nav > li {
		margin-left: 35px } }
		
.nav > li.first {
	margin-left: 0px }
	
@media (max-width: 1199px) {
	.nav > li.first {
		margin-top: -5px } }
		
.nav > li > a {
	position: relative;
	display: block;
	padding: 10px 0 }
	
.nav > li > a:hover,
.nav > li > a:focus {
	text-decoration: none;
	background-color: #eee }
	
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: #eee;
	border-color: #337ab7 }
	
.nav .nav-divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5 }
	
.nav > li > a > img {
	max-width: none }
	
@media (min-width: 1200px) {
	.navbar-header {
    	float: left } }
    	
.navbar-collapse {
	padding-top: 8px;
	margin-top: 18px;
	overflow-x: visible;
	-webkit-overflow-scrolling: touch;
	border-top: 1px solid transparent;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
	margin-left: 0!important;
	margin-right: 0!important }
	
.navbar-collapse .divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5 }
	
.navbar-collapse.in {
	overflow-y: auto }
	
@media (min-width: 1200px) {
	.navbar-collapse {
		padding-top: 26px;
		margin-top: 0;
		width: auto;
		border-top: 0;
		-webkit-box-shadow: none;
            	box-shadow: none }
  	.navbar-collapse.collapse {
    	display: block !important;
		height: auto !important;
		padding-bottom: 0;
		overflow: visible !important }
  	.navbar-collapse.in {
  		overflow-y: visible }
  	.navbar-fixed-top .navbar-collapse,
  	.navbar-static-top .navbar-collapse {
    	padding-right: 0;
		padding-left: 0 } }
		
.navbar-fixed-top .navbar-collapse {
	max-height: 320px }
	
@media (max-device-width: 480px) and (orientation: landscape) {
	.navbar-fixed-top .navbar-collapse {
    	max-height: 200px } }
    	
.navbar-static-top {
	z-index: 1000;
	border-width: 0 0 1px }
	
@media (min-width: 1200px) {
	.navbar-static-top {
    	border-radius: 0 } }
    	
.navbar-fixed-top {
	z-index: 1030 }
	
@media (min-width: 1200px) {
	.navbar-fixed-top{
    	border-radius: 0 } }
    	
.navbar-fixed-top {
	top: 0;
	border: none }
	
.navbar-brand {
	float: left;
	padding-top: 26px;
	padding-bottom: 16px;
	font-size: 22px;
	line-height: 20px;
	height: 60px }
	
@media (min-width: 600px) {
	.navbar-brand {
		padding-top: 20px;
		padding-bottom: 16px } }
		
@media (min-width: 1200px) {
	.navbar-brand {
		padding-top: 28px;
		padding-bottom: 16px } }
		
.navbar-brand:hover,
.navbar-brand:focus {
	text-decoration: none }
	
.navbar-brand > img {
	display: block;
	height: 27px }
	
@media (min-width: 600px) {
	.navbar-brand > img {
		height: 38px } }
		
.navbar-toggle {
	position: relative;
	float: right;
	padding: 9px 0px;
	margin-top: 20px;
	margin-bottom: 4px;
	background-color: transparent;
	background-image: none;
	border: none;
	border-radius: 4px }
	
.navbar-toggle:focus {
	outline: 0 }
  
.navbar-toggle .icon-bar {
	display: block;
	width: 22px;
	height: 2px;
	border-radius: 1px }
  
.navbar-toggle .icon-bar + .icon-bar {
	margin-top: 6px }
	
@media (min-width: 1200px) {
	.navbar-toggle {
    	display: none } }
    	
.navbar-nav {
	margin-bottom: 0;
	font-size: 1em;
	font-weight: 300;
	text-transform: none }
  	
.navbar-nav > li > a {
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 20px }
	
@media (max-width: 1199px) {
	.navbar-nav .open .dropdown-menu {
	    position: static;
	    float: none;
	    width: auto;
	    margin-top: 9px;
	    padding-top: 10px;
	    padding-bottom: 0px;
	    background-color: transparent;
	    border: 0;
	    border-top: 1px solid #e5e5e5;
	    border-radius: 0;
	    -webkit-box-shadow: none;
	            box-shadow: none }
	.navbar-nav .open .dropdown-menu > li > a,
	.navbar-nav .open .dropdown-menu .dropdown-header {
    	padding: 5px 15px 5px 25px }
	.navbar-nav .open .dropdown-menu > li > a {
    	line-height: 20px }
	.navbar-nav .open .dropdown-menu > li > a:hover,
	.navbar-nav .open .dropdown-menu > li > a:focus {
    	background-image: none } }
    	
@media (min-width: 1200px) {
	.navbar-nav {
		float: left;
		margin: 0 0 5px 0 }
	.navbar-nav > li {
    	float: left }
	.navbar-nav > li > a {
    	padding-top: 15px;
		padding-bottom: 15px } }
		
.navbar-nav > li > .dropdown-menu {
	margin-top: 0;
	padding-top: 10px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px }
	
.navbar-text {
	margin-top: 15px;
	margin-bottom: 15px }
	
@media (min-width: 1200px) {
	.navbar-text {
    	float: left;
		margin-right: 15px;
		margin-left: 15px } }
		
@media (min-width: 1200px) {
	.navbar-left {
    	float: left !important }
	.navbar-right {
    	float: right !important;
		margin-right: 0 }
	.navbar-right ~ .navbar-right {
    	margin-right: 0 } }
    	
.navbar-default {
	background-color: inherit;
	border-color: #e7e7e7 }
	
.navbar-default .navbar-brand {
	color: #5a7496 }
	
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
	color: #5e5e5e;
	background-color: transparent }
	
.navbar-default .navbar-text {
	color: #5a7496 }
	
.navbar-default .navbar-nav > li > a {
	color: #fff }
	
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	color: #aaa;
	background-color: transparent }
	
.navbar-default .navbar-nav > .active > a {
	color: #aaa;
	background-color: transparent }
	
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
	color: #aaa;
	background-color: transparent }
	
.navbar-default .navbar-toggle {
	border-color: #5a7496 }
	
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
	background-color: transparent }
	
.navbar-default .navbar-toggle .icon-bar {
	background-color: #fff }
	
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	border-color: #e5e5e5 }
	
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
	color: #fff;
	background-color: transparent }
	
@media (max-width: 1199px) {
	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
    	color: #fff }
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    	color: #aaa;
		background-color: transparent }
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a,
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    	color: #aaa;
		background-color: #5a7496 } }
		
.navbar-default .navbar-link {
	color: #fff }
	
.navbar-default .navbar-link:hover {
	color: #aaa }
  
.navbar-default .btn-link {
	color: #fff }
  
.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
	color: #aaa }
	
.navbar-transparent {
	background-color: transparent
}

.nav .dropdown-menu {
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin: 0;
    border: none;
    text-transform: none;
    min-width: 200px;
    background: #aaa }

.nav .dropdown-menu a {
    padding: 8px 20px;
    color: #666;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    text-transform: none }

.nav .dropdown-menu a:hover {
    background: #5a7496;
    color: #fff }
    
.navbar-xo {
	background-color: #5a7496;
	border-bottom: 1px solid #ffffff }

/* Override bootstrap default */

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background: none }

/* Small Devices, Tablets */

@media only screen and (min-width : 1200px) {
    .nav .dropdown-menu {
    	-webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        margin: 0;
        border: none;
        text-transform: none;
        min-width: 180px;
        background: #aaa }
    .nav .dropdown-menu:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #aaa;
        position: absolute;
        right: 92px;
        top: -10px }
    .nav .dropdown-menu a {
        padding: 8px 20px;
        color: #fff;
        -webkit-opacity: 1;
        -moz-opacity: 1;
        opacity: 1;
        font-size: 92%; }
    .nav .dropdown-menu a:hover {
        background: #5a7496;
        color: #fff } }

/* Small Devices, Landscape */

@media (max-device-width: 480px) and (orientation: landscape) {
    .navbar-fixed-top .navbar-collapse {
        max-height: 200px } }


/* HERO
----------------------------------------------------*/

/* background setup */
.background {
	background-color: #ddd;
    background-repeat:no-repeat;
    -webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
    /* custom background-position */
    background-position:50% 50%;
    /* ie8- graceful degradation */
    background-position:50% 50%9 !important }

.fullscreen,
.content-a {
    width:100%;
    height:100%;
    overflow:hidden }

.fullscreen.overflow,
.fullscreen.overflow .content-a {
    height:auto;
    min-height:100% }

.content-a {
	margin-top: 45px;
    display:table }
    @media (min-width: 992px) {
	    .content-a {
		    margin-top: 60px } }

.content-b {
    display:table-cell;
    position:relative;
    vertical-align:middle;
    text-align:center;
    font-size: 92% }
    
.content-b p {
    color: #fff;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 10px;
    line-height: 1.5em } 
    @media (min-width: 768px) {
    	.content-b p {
        	font-size: 1.5em;
			font-weight: 100 } }
    @media (min-width: 992px) {
    	.content-b p {
        	width: 100%;
			font-weight: 100;
			line-height: 1.5em } }
    @media (min-width: 1200px) {
    	.content-b p {
        	width: 100%;
			font-weight: 100;
			line-height: 1.6em } }
			
.wrapper-bg {
    background-color: rgba(29, 29, 29, 0);
    padding: 30px 0 40px}


/* BUTTONS
----------------------------------------------------*/
   
.btn {
	display: inline-block;
	padding: 9px 20px;
	margin-top: 20px;
	margin-bottom: 0;
	text-transform: none;
	font-weight: 400;
	letter-spacing: 1px;
	line-height: 1.5em;
	color: #666;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	background-color: white;
	border: 1px solid white;
	border-radius: 6px }

.btn:hover,
.btn:focus,
.btn:active {
    color: white;
    background-color: #444445;
  	border: 1px solid #444445;
    text-decoration: none }     

.btn-intro {
	background-color: transparent;
    border-color: white;
    color: white;
	font-weight: 400 }

.btn-intro:hover,
.btn-intro:focus, 
.btn-intro:active {
	background-color: transparent;
	border-color: white;
	color: white }

.btn-highlight {
	background-color: transparent;
	border-color: #fff;
	color: #fff;
	font-weight: 400; }

.btn-highlight:hover,
.btn-highlight:focus, 
.btn-highlight:active {
    background-color: white;
    border-color: #5a7496;
    color: #5a7496 }
    
.btn-legal {
	display: inline-block;
	background-color: #fff;
	border-color: #444445;
	color: #444445;
	text-transform: none;
	font-size: 95%;
	font-weight: 200;
	line-height: 1.2em;
	margin-top: 3px;
	margin-bottom: 30px }

.btn-legal:hover,
.btn-legal:focus, 
.btn-legal:active {
    background-color: #444445;
    border-color: #444445;
    color: #fff }    
    @media (max-width: 420px) {
    .btn-legal {
		display:	block } }
    
.btn-grey {
	background-color: white;
	border-color: #666;
	color: #666;
	font-weight: 400 }

.btn-grey:hover,
.btn-grey:focus, 
.btn-grey:active {
    background-color: white;
    border-color: #444445;
    color: #444445 }
    
.btn-filter {
	background-color: transparent;
	border:0;
	color: #d3d3d3;
	font-weight: 400;
	-moz-osx-font-smoothing:grayscale;
	-webkit-font-smoothing:antialiased;
	font-size: 100%;
	padding:0px 16px;
	margin-top: 0;
	position:relative;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%) }

.btn-filter:hover,
.btn-filter:focus, 
.btn-filter:active {
    background-color: transparent;
    border:0;
    color: #fff }
    
.btn-modal,
.btn-nextpage {
	margin: 10px 0 }
	
.btn-lg {
	font-size: 16px }


/* CONTENT
----------------------------------------------------*/

.anchor-ghost {
	position: absolute;
	top: -125px }
	@media (min-width: 992px) {
		.anchor-ghost {
			top: -135px } }
	@media (min-width: 1200px) {
		.anchor-ghost {
			top: -160px } }
      
h1, h2, h3, h4, h5, h6 {
	color: #444;
	font-weight: 300;
	margin-top: 1.2em }  

h1 {
	font-size: 1.6em;
	font-weight: 300;
	padding-bottom: 20px;
	font-style: normal;
	border-bottom: 1px solid #ddd;
	margin-top: 0;
	margin-bottom: 1.35em;
	line-height: 1.2em;
	text-align: center }

.footer-h1 {
	text-align: left;
	border: none }

.content-b h1 {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0px;
	font-weight: 400;
	border: none;
	color: #fff;
    font-size: 200%;
    line-height: 1.4em;
    text-transform: uppercase;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6); }
    @media (min-width: 768px) {
    	.content-b h1 {
        	font-size: 280%;
			font-weight: 400;
			width: 100% } }
	@media (max-width: 600px) and (orientation: landscape) {
    	.content-b h1 {
        	font-size: 165% } }
			
.content-b h1 small {
	color: white }
	
.content-b h1.e404 {
	margin-bottom: 20px }
  
h2 {
	font-size: 1.4em;
	font-weight: 300;
	padding-top: 2em;
	padding-bottom: 20px;
	margin-bottom: 1em;
	border-bottom: 1px solid #ddd;
	line-height: 1.4em }

h2.legal,
.privacy h2 {
	margin-top: 1.35em;
    margin-bottom: 0;
    border: none!important;
	padding-bottom: 0!important
}
  
h2.cards {
	margin-bottom: 24px!important }

h2.map-info {
	padding-top: 0em }

h3 {
	font-size: 1.3em;
	font-weight: 300;
	padding-top: 2em;
	padding-bottom: 20px;
	margin-bottom: 1em;
	border-bottom: 1px solid #ddd;
	line-height: 1.4em }
  
h4 {
	font-size: 1.1em }

h5 {
	font-size: .9em;
	text-transform: uppercase;
	color: #666 }

.headline-center {
	text-align: center }
		
.headline-white {
	color: #e4e4e4;
	border-color: #e4e4e4 }

@media (max-width: 767px) {
	.headline-xs-center {
		text-align: center } }

@media (min-width: 992px) {
	h2 {
		font-size: 1.5em }
	h3 {
		font-size: 1.4em } }
		  
p {
	color: #444;
	margin: 0 0 24px;
	line-height: 1.8em;
	font-weight: 100;
	font-size: 110% }
	
p.nextpage {
	font-size: 150%;
	font-weight: 300;
	color: #fff;
	padding: 0;
	margin: 10px 0 }
	
p.xo-brand {
	font-family: 'Lato',"Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
	font-size: 17px;
	font-weight: 400;
	letter-spacing: 1px; }

.margin-1-1,
p.margin-1-1 {
	margin-left: 1px;
	margin-right: 1px }

.text-bold {
	font-weight: 400;
}
	
cite {
	font-style: normal;
	font-size: 130%;
	font-weight: 300;
	font-family: 'Lato', Helvetica, Arial, sans-serif;
	color: #fff;
	line-height: 1.5em }

.sm-center-md-left {
	text-align: center }
	@media (min-width: 992px) {
		.sm-center-md-left {
			text-align: left } }
			
.sm-center-md-right {
	text-align: center }
	@media (min-width: 992px) {
		.sm-center-md-right {
			text-align: right } }
			
ul {
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 24px;
	padding-left: 22px;
	color: #444;
	font-family: "Roboto", sans-serif;;
	line-height: 1.8em;
	font-weight: 100;
	font-size: 110% }
	
ul.produkte li {
	padding-left: 0;
	margin-left: 0 }

.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}



/* CARDS
----------------------------------------------------*/

@media (min-width: 768px) {
.morecolumnspace {
	margin-right: -30px } }

@media (min-width: 768px) {
.moregutterright {
	padding-right: 30px } }

.card {
	border-radius:6px;
	box-shadow:0 1px 4px rgba(0,0,0,.23);
	background-color:#FFF;
	margin-bottom:45px }

.card .image {
	width:100%;
	overflow:hidden;
	height:260px;
	border-radius:6px 6px 0 0;
	position:relative;
	-webkit-transform-style:preserve-3d;
	-moz-transform-style:preserve-3d;
	transform-style:preserve-3d }
	
.card .video {
	width:100%;
	overflow:hidden;
	height:260px;
	border-radius:6px;
	-webkit-border-radius:6px;
	position:relative;
	-webkit-transform-style:preserve-3d;
	-moz-transform-style:preserve-3d;
	transform-style:preserve-3d }

.card .studio-image {
	border-radius:6px }

.card .image img,
.card .video video {
	width:100% }

.card .content {
	padding:15px 30px 10px }

.card .title {
	margin:0 0 1em;
	color:#444;
	font-weight:300 }

.card .team-member-image {
	width:30px;
	height:30px;
	overflow:hidden;
	border-radius:50%;
	margin-right:1px }

.card .description {
	font-size:1em;
	color:#666 }

.card .footer {
	padding:0;
	background-color:transparent;
	line-height:30px }

.card .footer div {
	display:inline-block }

.card .team-member {
	font-size:12px;
	font-weight:400;
	text-transform:uppercase }
	
.card .service-descr {
	font-size:12px;
	font-weight:400;
	text-transform:uppercase }

.card h4 {
	font-size:1.5em;
	font-weight:400!important;
	margin:0 }

.card-service .image {
	height:auto;
	max-height: 250px; }
	
.card-service .image img {
	width:auto }

.card-service .service-descr {
	text-align:center;
	text-transform:none;
	margin-top:1em }

.card-service .title {
	line-height:24px }

.card-service .content {
	min-height:80px;
	text-align:center }

.card-service .footer {
	padding:0px 5px 30px;
	text-align: center }

.card-service .footer .btn {
	font-size:smaller }

.card-service hr {
	margin:-5px 30px 10px;
	border-color: #666 }

.card-table {
	margin-top: 45px;
	margin-bottom: 15px }

.card-table .image {
	height:auto;
	max-height: 175px }
	
.card-table .image img {
	width:auto }

.card-table .content {
	text-align:center;
	padding: 0 }

.card-table .footer {
	padding:0 20px 1px;
	text-align: left;
	font-size: 0.85em;
	text-align: justify }

	@media (max-width:999px) {
		.card-table .footer {
			padding: 15px 15px 1px 15px } }

.card-user .image {
	height:90px }

.card-user .team-member {
	text-align:center;
	text-transform:none;
	margin-top:-85px }

.card-user .team-member-image {
	width:140px;
	height:140px;
	border:5px solid #FFF;
	position:relative;
	margin-bottom:15px }

.card-user .title {
	line-height:24px }

.card-user .content {
	min-height:240px }

.card-user .footer {
	padding:0px 5px 30px;
	text-align: center }

.card-user .footer .btn {
	font-size:smaller }

.card-user hr {
	margin:5px 30px;
	border-color: #dedede }

.card-image,
.card-video {
	background-color:transparent;
	box-shadow:none;
	border-radius:0;
	width: 100%;
	position: relative }

.card-image .image,
.card-video .video {
	-webkit-border-radius:6px;
	border-radius:6px;
	height:100%!important;
	max-height:390px }
	
.card-video .video video {
	border-radius: 6px;
	-webkit-border-radius:6px;
	overflow:hidden }
	
.corner-top-left, .corner-top-right, .corner-bot-left, .corner-bot-right {
    width: 10px;
    height: 10px;
    position: absolute;
    background: url(/xo-video/corners.png) no-repeat;
    z-index: 1 }

.corner-top-left {
	top: 0;
	left: 0;
	background-position: 0 0 }
	
.corner-top-right {
	top: 0;
	right: 0;
	background-position: -10px 0 }
	
.corner-bot-left {
	bottom: 4px;
	left: 0;
	background-position: 0 -10px }

.corner-bot-right {
	bottom: 4px;
	right: 0;
	background-position: -10px -10px }

.card .filter {
	position:absolute;
	z-index:2;
	background-color:rgba(0,0,0,.68);
	top:0;
	left:0;
	width:100%;
	height:100%;
	text-align:center;
	opacity:0;
	filter:alpha(opacity=0) }

.card:hover .filter {
	opacity:1;
	filter:alpha(opacity=100) }

.card .btn-hover {
	opacity:0;
	filter:alpha(opacity=0) }

.card:hover .btn-hover {
	opacity:1;filter:alpha(opacity=100) }

.filter.filter-white {
	background-color:rgba(255,255,255,.91) }
	
.filter.filter-blue {
	background-color:rgba(90,116,150,.75) }

.filter.filter-green {
	background-color:rgba(4,124,10,.69) }

.filter.filter-red {
	background-color:rgba(252,13,0,.69) }

.filter.filter-purple {
	background-color:rgba(129,80,154,.55) }


/* PE-Icons
----------------------------------------------------*/


.info {
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center }

	@media (min-width: 992px) {
		.info {
		    margin-top: 20px;
		    margin-bottom: 45px	} }

.info .icon {
    background: transparent;
    margin-top: 0;
    padding: 8px; }

.icon i {
    color: #6b9ad6;
    font-size: 70px }
    
.info:hover > .icon i,
.info:focus > .icon i,
.info:active > .icon i {
	color: #5a7496 }

.info h5 {
	color: #444;
	font-size: 1.1em;
	margin-top: .8em }

.info p {
	font-size: 1em }


/* CAROUSEL
----------------------------------------------------*/

carousel-inner>.item>a>img,
.carousel-inner>.item>img {
	display:block;
	max-width:100%;
	height:auto }

.carousel {
	position:relative }

.carousel-inner {
	position:relative;
	width:100%;
	overflow:hidden }

.carousel-inner>.item {
	position:relative;
	display:none;
	-webkit-transition:.6s ease-in-out left;
	-o-transition:.6s ease-in-out left;
	transition:.6s ease-in-out left }

.carousel-inner>.item>a>img,
.carousel-inner>.item>img {
	line-height:1 }

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
	display:block }

.carousel-inner>.active {
	left:0 }

.carousel-inner>.next,
.carousel-inner>.prev {
	position:absolute;
	top:0;
	width:100% }

.carousel-inner>.next {
	left:100% }

.carousel-inner>.prev {
	left:-100% }

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
	left:0 }
	
.carousel-inner>.active.left {
	left:-100% }

.carousel-inner>.active.right {
	left:100% }

.carousel-indicators {
	position:absolute;
	bottom:10px;
	left:50%;
	z-index:15;
	width:60%;
	padding-left:0;
	margin-left:-30%;
	text-align:center;
	list-style:none }

.carousel-indicators li {
	display:inline-block;
	width:10px;
	height:10px;
	margin:1px;
	text-indent:-999px;
	cursor:pointer;
	background-color:#000\9;
	background-color:rgba(0,0,0,0);
	border:1px solid #fff;
	border-radius:10px }

.carousel-indicators li.active {
	width:10px;
	height:10px;
	margin:1px;
	background-color:#fff }

@media screen and (min-width:768px){
	.carousel-indicators {
		bottom:20px } }

.carousel-indicators {
	position:relative;
	bottom:0 }
	
.carousel-indicators li {
	margin:1px 5px 1px 5px;
	border-color:#fff }
	
.carousel-indicators li.active {
	margin:1px 5px 1px 5px;
	background-color:#fff }

.carousel-inner>.item>a>img,
.carousel-inner>.item>img {
	display:block;
	margin:0 auto;
	height:auto;
	border-radius:10px }
	
.carousel.fade {
	opacity:1 }
	
.carousel.fade .item {
	transition:opacity ease-in-out .8s;
	left:0!important;
	opacity:0;
	top:0;
	position:absolute;
	width:100%;
	display:block!important;
	z-index:1 }

.carousel.fade .item,
.carousel.fade .item.active {
	-moz-transition:opacity ease-in-out .8s;
	-o-transition:opacity ease-in-out .8s;
	-webkit-transition:opacity ease-in-out .8s }

.carousel.fade .item:first-child {
	top:auto;
	position:relative }

.carousel.fade .item.active {
	opacity:1;
	transition:opacity ease-in-out .8s;
	z-index:2 }

.carousel,
.carousel-inner,
.carousel-indicators {
	margin-bottom:0;
	padding-bottom:0 }


/* Table
--------------------------------------------------*/

table {
	border-spacing: 0;
	border-collapse: collapse;
	font-size: 0.85em }

td,
th {
	padding: 0 }
	
table {
	background-color: transparent }
	
th {
	font-weight: bold!important }
	
th,
td {
 	text-align: left }
 	
th.text-right,
td.text-right {
 	text-align: right }
 	
.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 5px }
	
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	padding: 8px;
	line-height: 1.42857143;
	vertical-align: top;
	border-top: 0px solid #ddd }
	
.table > tfoot > tr > td {
	padding-top: 17px!important;
	padding-bottom: 17px!important }
	
.table > thead > tr > th {
	vertical-align: bottom;
	border-bottom: 1px solid #ccc }
	
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
	border-top: 0 }
	
.table > tbody + tbody {
	border-top: 2px solid #ddd }
	
.table .table {
	background-color: #fff }
	
.table-striped > tbody > tr:nth-child(odd) {
	background-color: #e4e4e4 }
	
table col[class*="col-"] {
	position: static;
	display: table-column;
	float: none }
	
table td[class*="col-"],
table th[class*="col-"] {
	position: static;
	display: table-cell;
	float: none }
	
.table-responsive {
	min-height: .01%;
	overflow-x: auto }
	
	@media screen and (max-width: 999px) {
		.table-responsive {
			width: 100%;
			margin-bottom: 15px;
			overflow-y: hidden;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			border: 0px solid #ddd }
		.table-responsive > .table {
			margin-bottom: 0 }
		.table-responsive > .table > thead > tr > th,
		.table-responsive > .table > tbody > tr > th,
		.table-responsive > .table > tfoot > tr > th,
		.table-responsive > .table > thead > tr > td,
		.table-responsive > .table > tbody > tr > td,
		.table-responsive > .table > tfoot > tr > td {
			white-space: nowrap } }

.table>thead>tr>th {
	border-bottom-width:1px }

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
	padding:12px 20px;
	vertical-align:middle }

	@media (max-width:999px) {
		.table>tbody>tr>td,
		.table>tbody>tr>th,
		.table>tfoot>tr>td,
		.table>tfoot>tr>th,
		.table>thead>tr>td,
		.table>thead>tr>th {
			padding:12px 15px } }

.table>tbody>tr {
	position:relative }

.table>tbody>tr:hover .td-actions .btn {
	opacity:1;
	filter:alpha(opacity=100) }

@media (max-width:999px) {
	.table-responsive {
		width:100%;
		margin-bottom:0;
		overflow-x:scroll;
		overflow-y:hidden;
		border:0px solid #ddd;
		-ms-overflow-style:-ms-autohiding-scrollbar;
		-webkit-overflow-scrolling:touch } }

tr.bottomborder {
	border-bottom: 1px solid #ccc }


/* Modal
----------------------------------------------------*/

.modal-dialog {
	width: 722px }

.modal-content {
	background-color: #e4e4e4 }
	
.modal-title {
	font-size: 115%;
	font-weight: 400;
	border: none;
	padding: 10px 0 5px 0 }

.modal-header {
	text-align: center }

.modal-footer {
	text-align: center }
	
/* scroll fixes */
.modal-open .modal {
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow-y: scroll }


/* CONTACT
----------------------------------------------------*/

form {
	margin: 0 0 24px }

fieldset {
	padding: 0;
	margin: 0;
	border: 0 }

label,
input,
button,
select,
textarea {
	font-size: 17px;
	font-weight: 300;
	line-height: 35px }

input,
button,
select,
textarea {
	font-family: "Open Sans", sans-serif }

label {
	display: block;
	margin-bottom: 0;
	margin-top: 2px;
	color: #222 }

select,
textarea,
input[type="text"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="checkbox"] {
	display: inline-block;
	height: 45px;
	padding: 6px 1%;
	font-size: 17px;
	line-height: 35px;
	color: #444555;
	vertical-align: middle }

input,
textarea,
.uneditable-input {
	width: 100%  }

textarea {
	height: auto }

textarea,
input[type="text"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="checkbox"] {
	background-color: white;
	border: 1px solid #cccccc }

textarea:focus,
input[type="text"]:focus,
input[type="datetime"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="checkbox"]:focus {
	border-color: #444445;
    outline: 0;
    outline: thin dotted \9;
    /* IE6-9 */ }

.form-control,
select {
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  font-size: 17px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #bcb7b0;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.form-control:focus,
select:focus {
  border-color: #89ba16;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control::-moz-placeholder,
select::-moz-placeholder {
  color: #555555;
  opacity: 1;
  font-weight: 300;
  font-size: 100%
}
.form-control:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #555555;
  font-weight: 300;
  font-size: 100%
}
.form-control::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #555555;
  font-weight: 300;
  font-size: 100%
}
.form-control::-ms-expand,
select::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
textarea.form-control,
textarea.select {
  height: auto;
}

.form-group {
	margin-bottom: 24px }

.form-group-privacyHint {
	margin-bottom: 20px }


.form-group .errors {
	margin-top: -12px;
	margin-bottom: 12px; }
	.form-group .errors span {
		color: white;
		display: inline-block;
		padding: 0 12px;
		background-color: #cc0000 }

.checkbox {
  padding-left: 20px;
  line-height: 1.6em; }
  .checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 8px;
    line-height: 1.6em;
    font-weight: 300;
    font-size: 85%;  }
    @media (min-width: 520px){
	    .checkbox label {
		    text-align: justify; } }
    .checkbox label::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-top: 4px;
      margin-left: -20px;
      border: 1px solid #cccccc;
      border-radius: 0px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .checkbox label::after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #555555; }
  .checkbox input[type="checkbox"] {
    opacity: 0; }
    .checkbox input[type="checkbox"]:focus + label::before {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .checkbox input[type="checkbox"]:checked + label::after {
      font-family: 'FontAwesome';
      content: "\f00c"; }
  .checkbox.checkbox-inline {
    margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca; }
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #89ba16;
  border-color: #89ba16; }
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff; }




/* MAPS
----------------------------------------------------*/

#map-container {
	height: 450px;
	margin-top: 0;
	border-top: 1px solid #dedede;
	border-bottom: 1px solid #dedede;
	overflow: hidden }
	@media (min-width: 800px) {
    	#map-container {
    		margin-top: 0px } }

    .info_content {
	    text-align: center;
	    margin-left: 10px;
	    margin-right: 10px }
	    
	.info_content img {
		margin: 12px 0 12px 0 }
		
	.info_content svg {
		margin: 12px 0 12px 0 }

.gm-style-iw-c {
	border-radius: 4px!important;
}
    
.gm-style-iw-d {
	padding: 15px;
	margin: 0 }




/* FOOTER
----------------------------------------------------*/

footer {
	background: #444;
	border-top: none;
	margin-top: 0;
	padding: 0;
	text-align: center;
	font-weight: 300;
	font-size: .85em;
	clear: both }
	@media (min-width: 768px) {
    	footer {
			text-align: left } }

footer .wrapper {
    *zoom: 1; 
    font-size: 85%;
    color: #FFF }

footer .wrapper:before,
footer .wrapper:after {
    display: table;
    content: "";
    line-height: 0 }

footer .wrapper:after {
      clear: both }

footer h1 {
	font-weight: 700;
	color: #fff;
	padding: 0;
	line-height: 2em;
	font-size: 24px;
	font-weight: 300;
	margin: 5px 0 0 0 }

footer p {
    color: #FFF;
    margin: 0;
    line-height: 2em;
    font-size: 100% }
                
footer ul.ulright {
	list-style: none;
	padding: 0;
	line-height: 2.6em;
	margin-top: 10px }
	   
	@media (min-width: 768px) {
    	footer ul.ulright {
	    	line-height: 2em;
        	float: right;
			margin-right: 1.2em }
		footer .xo-concept {
        	margin: 0;
			float: right;
			line-height: 2em;
			letter-spacing: .1px } }
        
footer a {
    color: #FFF!important }
    
footer a.active {
  	color: #444445!important }
  	
footer a:hover,
footer a:focus,
footer a:active {
    color: #999!important;
    text-decoration: none }
    
footer a.active:hover,
footer a.active:focus,
footer a.active:active {
    text-decoration: none;
    color: #444445!important }

@media (max-width: 767px) {  	
	footer span.xsmall {
  		display: none } }

.accent {
	border-bottom: 1px solid #ddd; 
	width: 100%; 
	height: 1px;
	margin-bottom: 24px;
	margin-top: 0px }


/* SOCIAL
----------------------------------------------------*/

.social {
    padding: 0;
    margin: 10px 0 0px;
    list-style: none;
    position: relative;
    z-index: 100 }
    
    @media (max-width: 767px) {
	.social {
		margin-bottom: 24px
	}
}


.social li {
    display: inline-block;
    margin-right: 16px }
    
.social li.last {
	margin-right: 0 }

.social li a {
    display: table;
    position: relative }

.social li a i {
	color: #ffffff;
	border: 1px solid #fff; 
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 80px;
    height: 39px;
    font-size: 21px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out }
    
    @media (min-device-width: 1025px) {  	
		.social li a i {
			width: 68px;
			height: 35px;
			font-size: 22px } }

.social li a i:after {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out }

.social li a:hover i {
    color: #444445 }

.social li a:hover i:after {
    height: 39px }
    
    @media (min-device-width: 1025px) {
	    .social li a:hover i:after {
			height: 35px } }

.social a .fa-facebook:after {
    background: #3b5998 }

.social a .fa-google-plus:after {
    background: #dd4b39 }

.social a .fa-twitter:after {
    background: #00aced }

.social a .fa-pinterest:after {
    background: #CB2027 }

.social a .fa-behance:after {
    background: #1769FF }
    
.social a .fa-dribbble:after {
    background: #EA4C89 }

.social a .fa-instagram:after {
    background: #3F729B }
    
.social a .fa-envelope-o:after {
    background:  #fff }

.social a .fa-phone:after {
    background:  #fff }

.social a .fa-comment-o:after {
    background:  #43D854 }  

@media (min-device-width: 1025px) {
	li.mobile-only {
  		display: none } }

@media (max-device-width : 1024px) {
	li.mobile-only {
  		display: inline-block } }

@media (min-device-width: 1025px) {
	li.desktop-only {
  		display: inline-block } }

@media (max-device-width : 1024px) {
	li.desktop-only {
  		display: none } }
  		
@media (min-width: 568px) {
	h1.mobile-only,
	h2.mobile-only {
  		display: none } }

@media (max-width : 567px) {
	h1.mobile-only,
	h2.mobile-only {
  		display: block } }

@media (min-width: 568px) {
	h1.desktop-only,
	h2.desktop-only {
  		display: block } }

@media (max-width : 567px) {
	h1.desktop-only,
	h2.desktop-only {
  		display: none } }

@import 'fonts';
@import 'font-awesome';